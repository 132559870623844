import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessagesService } from "../../services/messages/messages.service";
import { LoginService } from "../../services/login/login.service";


@Component({
  selector: 'app-chatwindow',
  templateUrl: './chatwindow.component.html',
  styleUrls: ['./chatwindow.component.scss']
})
export class ChatwindowComponent implements OnInit, OnDestroy {
  public messages: any[] = [];
  public username: string = sessionStorage.getItem('username');
  public operatorIsAvailable: boolean = true;
  protected isLoadingChat: boolean;

  constructor(private messagesService: MessagesService, private loginService: LoginService) {
  }

  public ngOnInit(): void {

    // Set the right sessions from the parent.
    window.addEventListener('message', this.handlePostedMessage.bind(this), false);

    setInterval(() => {
      if (sessionStorage.getItem('operator_id')) {
        let operatorId = sessionStorage.getItem('operator_id');
        this.pollOperator(operatorId);
      }
    }, 30000);

    this.initChat();
  }

  public ngOnDestroy() {
    window.removeEventListener('message', this.handlePostedMessage.bind(this), false);
  }

  protected enlargeImage(event): void {

    let imageSrc = event.composedPath()[0].currentSrc;
    let win = window.open();
    let iFrame = '<iframe src="' + imageSrc + '" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>';

    win?.document.write(iFrame);
  }

  protected login() {
    this.isLoadingChat = true;
    // Create a conversation object
    this.loginService.createConversation(sessionStorage.getItem('customer_user_id')).subscribe({
      next: data => {

        // @ts-ignore
        sessionStorage.setItem('company_name', data.Conversation.company_name);
        // @ts-ignore
        sessionStorage.setItem('operator_id', data.Conversation.operator_user_id);
        // @ts-ignore
        sessionStorage.setItem('order_id', data.Conversation.order_id);
        // @ts-ignore
        sessionStorage.setItem('conversation_id', data.Conversation.id);

        this.operatorIsAvailable = data.Conversation.operator_user_id !== "" || data.Conversation.operator_user_id !== null || true;

        if (!this.operatorIsAvailable) {
          this.translateNotAvailable(sessionStorage.getItem('language'));
        }
        this.initChat();
        this.isLoadingChat = false;
      }, error: (data) => {
        console.log('Error: ' + data);
      }
    });
  }

  private initChat() {
    this.loadAllMessages();
    this.changeHeightChatWindow();

    setTimeout(() => {
      this.scrollToBottom();
      this.enlargeImageSocket();
    }, 500);
  }

  // Display all messages that are typed.
  private loadAllMessages(): void {

    //Variables.
    let conversationId = sessionStorage.getItem('conversation_id');
    if (conversationId) {
      // Get all messages from the current conversation and put in into the 'messages' variable.
      this.messagesService.getMessage(conversationId).subscribe((data) => {
        this.messages = data.MessageList;

        if (data.MessageList) {
          let messages = data.MessageList;

          for (let i = 0; i < messages.length; i++) {

            let data_clientId = sessionStorage.getItem('username');

            // If the message(s) match the current session user ID, they will get a class that shows what messages belong to who.
            if (messages[i].username == data_clientId) {
              messages[i].is_current_user = true
            }
          }
        }

        setTimeout(() => {
          this.scrollToBottom();
        }, 350);
      });
    }
  }

  private changeHeightChatWindow(): void {

    let windowHeight = window.outerHeight;
    let messagesLoop = document.querySelector('.js-messages-loop');

    if (windowHeight > 1000) {
      messagesLoop.classList.remove('is-narrow-screen');
      messagesLoop.classList.add('is-tall-screen');
    } else {
      messagesLoop.classList.remove('is-tall-screen');
      messagesLoop.classList.add('is-narrow-screen');
    }
  }

  private pollOperator(operatorId: string) {

    /**
     * This functions checks whether an operator is still present every x seconds.
     */
    this.loginService.checkIfOperatorAvailable(operatorId).subscribe({
      next: (data) => {
        this.operatorIsAvailable = data == 'true';
      }
    });
  }

  private scrollToBottom(): void {
    let bottom = document.getElementById('messages-loop')!;

    bottom.scrollTop = bottom.scrollHeight;
  }

//  TRANSLATION

  private enlargeImageSocket(): void {
    document.addEventListener('click', (event) => {

      // @ts-ignore
      let targetImage = event.target.classList;


      if (targetImage.contains('image-is-from-socket')) {

        // @ts-ignore
        let imageSrc = event.composedPath()[0].currentSrc;
        let win = window.open();
        let iFrame = '<iframe src="' + imageSrc + '" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>';

        win.document.write(iFrame);
        return;
      }
    });

  }

  private translateNotAvailable(language: string): void {
    switch (language) {
      case 'nl':
        document.querySelector('.translate-not-available')
          .innerHTML = 'Sorry, er is momenteel geen medewerker beschikbaar. Probeer het later opnieuw.';
        break;
      case 'en':
        document.querySelector('.translate-not-available')
          .innerHTML = 'Sorry, there is currently no employee available to talk to. Please try again later.';
        break;
      case 'de':
        document.querySelector('.translate-not-available')
          .innerHTML = 'Entschuldigung, derzeit ist kein Mitarbeiter verfügbar, mit dem Sie sprechen können. Bitte versuchen Sie es später erneut.';
        break;
      case 'fr':
        document.querySelector('.translate-not-available')
          .innerHTML = "Désolé, il n'y a actuellement aucun employé à qui parler. Veuillez réessayer plus tard.";
        break;
      case 'es':
        document.querySelector('.translate-not-available')
          .innerHTML = 'Lo sentimos, actualmente no hay ningún empleado disponible para hablar. Por favor, inténtelo de nuevo más tarde.';
        break;
      default:
        document.querySelector('.translate-not-available')
          .innerHTML = 'Sorry, there is currently no employee available to talk to. Please try again later.';
    }
  }

  private handlePostedMessage(event: MessageEvent<any>) {
    sessionStorage.setItem('language', event.data.language);
    sessionStorage.setItem('customer_user_id', event.data.customer_user_id);

    if (event.data.username == null) {
      sessionStorage.setItem('username', event.data.submitted_by);
    } else {
      sessionStorage.setItem('username', event.data.username);
    }

    // Check if the primary color is present in the main application.
    if (event.data.primary_color) {
      sessionStorage.setItem('primary_color', event.data.primary_color);
    } else {
      sessionStorage.setItem('primary_color', '#3c0');
    }

    // Same goes for the secondary color.
    if (event.data.secondary_color) {
      sessionStorage.setItem('secondary_color', event.data.secondary_color);
    } else {
      sessionStorage.setItem('secondary_color', '#4A4A4A');
    }
  }

}


